exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-applications-jsx": () => import("./../../../src/pages/applications.jsx" /* webpackChunkName: "component---src-pages-applications-jsx" */),
  "component---src-pages-case-studies-jsx": () => import("./../../../src/pages/case-studies.jsx" /* webpackChunkName: "component---src-pages-case-studies-jsx" */),
  "component---src-pages-contribute-jsx": () => import("./../../../src/pages/contribute.jsx" /* webpackChunkName: "component---src-pages-contribute-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-infrastructure-jsx": () => import("./../../../src/pages/infrastructure.jsx" /* webpackChunkName: "component---src-pages-infrastructure-jsx" */),
  "component---src-pages-newsletter-jsx": () => import("./../../../src/pages/newsletter.jsx" /* webpackChunkName: "component---src-pages-newsletter-jsx" */),
  "component---src-pages-resources-jsx": () => import("./../../../src/pages/resources.jsx" /* webpackChunkName: "component---src-pages-resources-jsx" */),
  "component---src-pages-summit-2023-jsx": () => import("./../../../src/pages/summit-2023.jsx" /* webpackChunkName: "component---src-pages-summit-2023-jsx" */),
  "component---src-pages-summit-2023-talks-jsx": () => import("./../../../src/pages/summit-2023-talks.jsx" /* webpackChunkName: "component---src-pages-summit-2023-talks-jsx" */),
  "component---src-pages-summit-2024-jsx": () => import("./../../../src/pages/summit-2024.jsx" /* webpackChunkName: "component---src-pages-summit-2024-jsx" */),
  "component---src-pages-summit-2024-livestream-jsx": () => import("./../../../src/pages/summit-2024-livestream.jsx" /* webpackChunkName: "component---src-pages-summit-2024-livestream-jsx" */),
  "component---src-pages-summit-2024-schedule-jsx": () => import("./../../../src/pages/summit-2024-schedule.jsx" /* webpackChunkName: "component---src-pages-summit-2024-schedule-jsx" */),
  "component---src-pages-summit-2024-talks-jsx": () => import("./../../../src/pages/summit-2024-talks.jsx" /* webpackChunkName: "component---src-pages-summit-2024-talks-jsx" */),
  "component---src-pages-swag-jsx": () => import("./../../../src/pages/swag.jsx" /* webpackChunkName: "component---src-pages-swag-jsx" */),
  "component---src-templates-blog-jsx": () => import("./../../../src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2020-11-20-ebpf-updates-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/content/blog-posts/2020-11-20-ebpf-updates/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2020-11-20-ebpf-updates-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2020-12-21-ebpf-updates-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/content/blog-posts/2020-12-21-ebpf-updates/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2020-12-21-ebpf-updates-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2021-01-22-ebpf-updates-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/content/blog-posts/2021-01-22-ebpf-updates/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2021-01-22-ebpf-updates-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2021-02-23-ebpf-updates-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/content/blog-posts/2021-02-23-ebpf-updates/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2021-02-23-ebpf-updates-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2021-05-04-ebpf-updates-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/content/blog-posts/2021-05-04-ebpf-updates/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2021-05-04-ebpf-updates-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2022-11-17-why-ebpf-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/content/blog-posts/2022-11-17-why-ebpf/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2022-11-17-why-ebpf-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2023-04-01-excelbpf-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/content/blog-posts/2023-04-01-excelbpf/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2023-04-01-excelbpf-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2023-11-08-ebpf-documentary-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/content/blog-posts/2023-11-08-ebpf-documentary/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2023-11-08-ebpf-documentary-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2024-06-13-ebpf-licensing-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/content/blog-posts/2024-06-13-ebpf-licensing/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2024-06-13-ebpf-licensing-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2024-10-09-ebpf-summit-wrap-up-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/content/blog-posts/2024-10-09-ebpf-summit-wrap-up/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2024-10-09-ebpf-summit-wrap-up-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2025-04-01-tolkein-ring-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/content/blog-posts/2025-04-01-tolkein-ring/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-content-blog-posts-2025-04-01-tolkein-ring-index-md" */),
  "component---src-templates-events-jsx": () => import("./../../../src/templates/events.jsx" /* webpackChunkName: "component---src-templates-events-jsx" */),
  "component---src-templates-get-started-jsx": () => import("./../../../src/templates/get-started.jsx" /* webpackChunkName: "component---src-templates-get-started-jsx" */),
  "component---src-templates-labs-jsx": () => import("./../../../src/templates/labs.jsx" /* webpackChunkName: "component---src-templates-labs-jsx" */),
  "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-es-md": () => import("./../../../src/templates/static.jsx?__contentFilePath=/opt/build/repo/content/static-pages/what-is-ebpf/index.es.md" /* webpackChunkName: "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-es-md" */),
  "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-fr-fr-md": () => import("./../../../src/templates/static.jsx?__contentFilePath=/opt/build/repo/content/static-pages/what-is-ebpf/index.fr-fr.md" /* webpackChunkName: "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-fr-fr-md" */),
  "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-hi-in-md": () => import("./../../../src/templates/static.jsx?__contentFilePath=/opt/build/repo/content/static-pages/what-is-ebpf/index.hi-in.md" /* webpackChunkName: "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-hi-in-md" */),
  "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-it-it-md": () => import("./../../../src/templates/static.jsx?__contentFilePath=/opt/build/repo/content/static-pages/what-is-ebpf/index.it-it.md" /* webpackChunkName: "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-it-it-md" */),
  "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-ja-md": () => import("./../../../src/templates/static.jsx?__contentFilePath=/opt/build/repo/content/static-pages/what-is-ebpf/index.ja.md" /* webpackChunkName: "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-ja-md" */),
  "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-ko-kr-md": () => import("./../../../src/templates/static.jsx?__contentFilePath=/opt/build/repo/content/static-pages/what-is-ebpf/index.ko-kr.md" /* webpackChunkName: "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-ko-kr-md" */),
  "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-md": () => import("./../../../src/templates/static.jsx?__contentFilePath=/opt/build/repo/content/static-pages/what-is-ebpf/index.md" /* webpackChunkName: "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-md" */),
  "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-pt-br-md": () => import("./../../../src/templates/static.jsx?__contentFilePath=/opt/build/repo/content/static-pages/what-is-ebpf/index.pt-br.md" /* webpackChunkName: "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-pt-br-md" */),
  "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-pt-md": () => import("./../../../src/templates/static.jsx?__contentFilePath=/opt/build/repo/content/static-pages/what-is-ebpf/index.pt.md" /* webpackChunkName: "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-pt-md" */),
  "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-sw-md": () => import("./../../../src/templates/static.jsx?__contentFilePath=/opt/build/repo/content/static-pages/what-is-ebpf/index.sw.md" /* webpackChunkName: "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-sw-md" */),
  "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-zh-hans-md": () => import("./../../../src/templates/static.jsx?__contentFilePath=/opt/build/repo/content/static-pages/what-is-ebpf/index.zh-hans.md" /* webpackChunkName: "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-zh-hans-md" */),
  "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-zh-hant-md": () => import("./../../../src/templates/static.jsx?__contentFilePath=/opt/build/repo/content/static-pages/what-is-ebpf/index.zh-hant.md" /* webpackChunkName: "component---src-templates-static-jsx-content-file-path-content-static-pages-what-is-ebpf-index-zh-hant-md" */)
}

